import React from 'react'
import {Button, Modal} from 'react-bootstrap'
import {Link} from 'gatsby'
import 'components/css/modal.css'
import {Span} from 'components'

function Sales() {
  return (
    <div className="modal-view">
      <Link to="/careers" state={{ modal: false }}>
        <Button className="close" id="close-button">
         <Span aria-hidden="true">x</Span>
        </Button>
      </Link>

      <h2>HR COMPENSATION AND BENEFITS SPECIALIST</h2><hr/>
    <br/>
      <Modal.Title><h4>MINIMUM QUALIFICATIONS</h4></Modal.Title>
      <Modal.Body>
        <ul>
          <li>BA/BS in Business, Accountancy, Human Resource, Psychology or related field is preferred</li>
          <li>At least 2 years experience as Compensation and Benefits Specialist</li>
          <li>Must know how to compute and file Taxes</li>
          <li>Must be knowledgeable with Tax Laws</li>
          <li>Must know how to process Benefits (SSS, HDMF, PHIC) thru online or manual</li>
          <li>Must know how to compute BIR 2316</li>
          <li>Must be knowledgeable in Payroll Summaries</li>
          <li>Highly proficient with Word, Excel and PowerPoint</li>
          <li>Must be adept at problem-solving in a timely manner</li>
          <li>Ability to maintain employee confidentiality</li>
          <li>Superior attention to detail</li>
          <li>Ability to multitask and work with minimal supervision</li>
          <li>Must possess strong interpersonal skills</li>
          <li>Strong communication skills, both written and orally</li>
          <li>Must be results-oriented</li>
          <li>Must be organized, accurate, thorough, and able to monitor work for quality</li>
        </ul>
      </Modal.Body>
      <a href="mailto: careers@digipay.ph" className="resume"><Button className="resume-button">Send Resume</Button></a>
    </div>
  )
}

export default Sales
